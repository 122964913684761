<template>
  <a-layout-content class="content-wrapper">
    <router-view />
  </a-layout-content>
</template>

<script>

export default {
  name: 'CustomStyleContentWrapper'
}
</script>

<style lang="less" scoped>
.content-wrapper {
  padding: 20px 20px 30px;
  height: calc(100vh - 64px);
}
</style>
