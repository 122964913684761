import storage from 'store'

// 区分平台系统和客户系统
export const CLIENT_HEADER_KEY = 'Client-Type'
export const CLIENT_HEADER_VALUE = 'touch_screen'

// 跳过延长身份过期时间 header
export const SKIP_EXTEND_AUTH_EXPIRE_TIME_HEADER = 'Skip-Extend-Auth'

// 存储 token 的 key
export const ACCESS_TOKEN = 'Authorization'
// token 请求前缀
export const TOKEN_PREFIX = 'Bearer'
// 刷新token header 中的 key
export const NEW_TOKEN = 'new-token'
export const ORIGINAL_USER = 'original_user'

export function getToken() {
  return storage.get(ACCESS_TOKEN)
}

// 请求中需要放置到header中的token字符串
// Bearer xxx
export function getRequestToken() {
  const token = storage.get(ACCESS_TOKEN)
  if (token) {
    return `${TOKEN_PREFIX} ${token}`
  }

  return ''
}

export function setToken(token) {
  return storage.set(ACCESS_TOKEN, token)
}

export function removeToken() {
  return storage.remove(ACCESS_TOKEN)
}

export function setOriginalUserUid(originalUserUid) {
  return storage.set(ORIGINAL_USER, originalUserUid)
}

export function getOriginalUserUid() {
  return storage.get(ORIGINAL_USER)
}

export function removeOriginalUserUid() {
  return storage.remove(ORIGINAL_USER)
}

// 清除所有 storage 存储的内容
export function clearStorage() {
  removeToken()
  removeOriginalUserUid()
}
