// import BasicLayout from '@/layouts/BasicLayout'
// import Empty from '@/layouts/Empty'
import CustomStyleLayout from '@/layouts/CustomStyleLayout'

// meta 部分说明
// 如 meta: { title: '用户管理', permission: 'users', menu: 'users' }
// permission 用于权限控制，api 返回的权限列表中有 users 才可以访问
// menu       用于菜单选中高亮，如果不是菜单则可以不传值，值需要和 a-menu-item key="users" 的key值相同
// title      用于浏览器 tab 显示以及面包屑显示
//            - 以 ':' 开头的 title 为动态的值占位符, 用作业务逻辑中的值替换
//              可使用 SetDynamicBreadCrumbsPlaceholder 方法替换占位符的值, 如：
//              this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agent_name': '客户1' })
//            - 还提供了 ConcatDynamicBreadCrumbs 方法追加面包屑, 常用于面包屑生成规则不统一，自行生成面包屑

// 异步路由(需要走权限控制)
export const asyncRouter = [
  {
    path: '/halls',
    component: CustomStyleLayout,
    children: [
      {
        path: ':id',
        name: 'hall_info',
        component: () => import('@/views/halls/show/index')
      }
    ]
  },

  {
    path: '/halls',
    component: CustomStyleLayout,
    children: [
      {
        path: ':id',
        component: () => import('@/views/halls/show/pc/in_use/index'),
        children: [
          {
            path: 'death',
            name: 'death_info',
            component: () => import('@/views/service_order_deaths/index')
          },
          {
            path: 'products',
            name: 'product_info',
            component: () => import('@/views/products/index')
          }
        ]
      }
    ]
  },

  {
    path: '/death',
    component: CustomStyleLayout,
    children: [
      {
        path: '/death',
        name: 'mobile_death_info',
        component: () => import('@/views/halls/show/mobile/in_use/Death')
      }
    ]
  },

  {
    path: '/products',
    component: CustomStyleLayout,
    children: [
      {
        path: '/products',
        name: 'mobile_products',
        component: () => import('@/views/products/mobile/Tab'),
        children: [
          {
            path: 'sub',
            name: 'sub_products',
            component: () => import('@/views/products/mobile/index.vue')
          },
          {
            path: 'already_sub',
            name: 'already_sub',
            component: () => import('@/views/products/mobile/AlreadySub.vue')
          }
        ]
      }
    ]
  }
]
