<template>
  <div class="box">
    <a-row>
      <a-col span="14">
        <a-breadcrumb>
          <a-breadcrumb-item>{{ $store.getters.funeralHomeName }}-厅房列表</a-breadcrumb-item>
        </a-breadcrumb>
      </a-col>
      <a-col span="10">
        <search @submit="submitSearch" />
      </a-col>
    </a-row>

    <a-row :gutter="24" class="hall-count-box">
      <a-col span="6">
        <div class="hall-count">
          <span class="count">{{ totalCount }}</span>
          <br>
          <span>厅房总数</span>
        </div>
      </a-col>
      <a-col span="6">
        <div class="hall-count">
          <span class="count">{{ inUseCount }}</span>
          <br>
          <span>在用厅房</span>
        </div>
      </a-col>
      <a-col span="6">
        <div class="hall-count">
          <span class="count">{{ idleCount }}</span>
          <br>
          <span>空闲厅房</span>
        </div>
      </a-col>
      <a-col span="6">
        <div class="hall-count">
          <span class="count">{{ pauseCount }}</span>
          <br>
          <span>暂停使用</span>
        </div>
      </a-col>
    </a-row>

    <div class="custom-card-box hall-box">
      <div class="hall">
        <div
          v-show="data.length > 0"
          v-for="(hall, stateIndex) in data"
          :key="stateIndex"
          class="hall-content-box"
        >
          <div class="hall-content" @click="toInfo(hall.id)">
            <img
              v-show="hall.images && hall.images.length > 0"
              class="hall-img"
              alt="img"
              v-for="(item, index) in hall.images"
              :key="index"
              :src="item.url"
            >
            <img
              v-show="!hall.images || hall.images.length === 0"
              class="hall-img"
              alt="img"
              src="/temp/no_picture.png"
            >

            <div class="hall-name custom-word-break-all">
              {{ hall.name }}
            </div>
            <div class="hall-status-in-use" v-if="hall.status_slug === 'in_use'">
              {{ hall.status }}
            </div>

            <div class="hall-status-idle" v-if="hall.status_slug === 'idle'">
              {{ hall.status }}
            </div>

            <div class="hall-status-pause" v-if="hall.status_slug === 'pause'">
              {{ hall.status }}
            </div>
          </div>
        </div>
      </div>

      <a-empty v-show="!data" image="/temp/no_data.png" />

      <pagination
        v-show="pagination.total_count > 0"
        :total-count="pagination.total_count"
        :page.sync="query.page"
        :per-page.sync="query.per_page"
        @change="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { findHalls } from '@/api/home'
import Pagination from '@/components/Pagination'
import Search from '@/views/home/pc/Search'
export default {
  name: 'Home',
  components: {
    Pagination,
    Search
  },
  data() {
    return {
      totalCount: 0,
      inUseCount: 0,
      idleCount: 0,
      pauseCount: 0,
      data: [],
      query: {},
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findHalls(this.query).then((res) => {
        this.totalCount = res.data.total_count
        this.inUseCount = res.data.in_use_count
        this.idleCount = res.data.idle_count
        this.pauseCount = res.data.pause_count
        this.data = res.data.halls
        this.pagination = res.pagination
      })
    },

    toInfo(id) {
      this.$router.push({ name: 'hall_info', params: { id: id }})
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  .hall-count-box {
    margin-bottom: 20px;
    color: #fff;

    .hall-count {
      padding: 10px 0 10px 20px;
      border-radius: 8px;
      background: linear-gradient(to top right, #2a4c39ed, #2a4c3966);

      .count {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  .hall-box {
    flex: 1;
    height: 100%;

    .hall {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .hall-content-box {
        flex-basis: 20%;
        margin-bottom: 20px;
      }
    }

    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .hall-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .hall-img {
        width: 200px;
        height: 130px;
      }

      .hall-name {
        color: white;
      }

      .hall-status-in-use {
        color: #ffd794ff;
      }

      .hall-status-idle {
        color: #97d689ff;
      }

      .hall-status-pause {
        color: #fc5037ff;
      }
    }
  }
}
</style>
