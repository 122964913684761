<template>
  <a-layout class="layout">
    <layout-header />

    <layout-content />
  </a-layout>
</template>

<script>
import LayoutHeader from '@/components/LayoutHeader/index'
import LayoutContent from '@/components/LayoutContent/CustomStyle'

export default {
  name: 'BasicLayout',
  components: {
    LayoutHeader,
    LayoutContent
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/variables";

// 内部 layout 相关
.layout {
  height: 100%;

  .section {
    padding: @section-padding;
  }
}
</style>
