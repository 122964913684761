<template>
  <div class="box">
    <img src="/temp/mobile_no_data.png" alt="logo">
    <span>暂无数据</span>
  </div>
</template>

<script>
export default {
  name: 'MobileNoData'
}
</script>
<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;

  img {
    width: 150px;
  }

  span {
    margin-top: 10px;
    font-size: 24px;
  }
}
</style>

